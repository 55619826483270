import React, { useEffect, useState } from "react";
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Swal from "sweetalert2";
import { Modal } from "react-bootstrap";
import { company_column_name } from "../../../components/FilteringTable/Columns";
import {searchCompany} from "../../../Api/ApiCalls";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import {handleError} from "../../../utils/SharedFunctions";
import {ApiSearchField} from "../../../components/ApiSearchField";
import { CreateUpdateCompanyForm } from "../../../components/Company/CreateUpdateCompanyForm";

function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}


export default function Company() {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [targetPage, setTargetPage] = useState(0);
  const history = useHistory();
  const [isLoadingQuery, setIsLoadingQuery] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebounce(searchQuery, 1000);

  useEffect(() => {
    setColumns(company_column_name);
    fetchData(1);

    if (!searchQuery) {
      return;
    }

    fetchData(1, debouncedSearchQuery);
  }, [debouncedSearchQuery]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {getTableProps,getTableBodyProps,headerGroups,prepareRow,page,} = tableInstance;


  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Company</h4>
          <button type="button" className="btn btn-secondary" onClick={() =>  setShowModal(true)}>
            + Add new Company
          </button>
        </div>
        {/*Table *****************/}
        <div className="card-body">
          <div className="table-responsive">
            <div className="d-flex row">
              <div className="d-flex gap-4">
                <div className={`d-flex flex-column mb-2 w-25`} style={{}}>
                  <ApiSearchField
                      searchQuery={searchQuery}
                      setSearchQuery={setSearchQuery}
                  />
                </div>
                {isLoadingQuery && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "15px",
                    }}
                  >
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <table {...getTableProps()} className="table dataTable display">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th

                      >
                        {column.render("Header")}

                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr{...row.getRowProps()} onClick={() => {
                      const companyId = row.original.id;
                      history.push({pathname: `/company/${companyId}`, state: row.original,});
                      }}
                    >
                      {row.cells.map((cell, index) => {
                        return (
                          <td key={index}{...cell.getCellProps()} className={"cursor-pointer"}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* This is only for footer if u require */}
            <div className="d-flex justify-content-between">
              <span>Page{" "}
                <strong>
                  {data.length === 0 ? "0 of 0" : currentPage + " of " + totalPages}
                </strong>
                {""}
              </span>
              <span className="table-index d-none">
                Go to page :{" "}
                <input type="number" className="ml-2" value={targetPage} onChange={(e) => {
                  setTargetPage(e.target.value);
                  fetchData(Number(e.target.value));
                }}
                />
              </span>
            </div>
            <div className="text-center mb-3">
              <div className="filter-pagination  mt-3">
                <button className=" previous-button" onClick={() => {
                    if (data.length === 0)
                      return;
                    fetchData(1);
                  }}
                  disabled={currentPage === 1 || data.length === 0}
                >
                  {"<<"}
                </button>

                <button className="previous-button" onClick={() => {
                    if (data.length === 0)
                      return;
                    fetchData(currentPage - 1);
                  }}
                  disabled={currentPage === 1 || data.length === 0}
                >
                  Previous
                </button>
                <button className="next-button" onClick={() => {
                    if (data.length === 0)
                      return;
                    fetchData(targetPage);
                  }}
                  disabled={currentPage === totalPages || data.length === 0}
                >
                  Next
                </button>
                <button className=" next-button" onClick={() => {
                    if (data.length === 0)
                      return;
                    fetchData(totalPages);
                  }}
                  disabled={currentPage === totalPages || data.length === 0}
                >
                  {">>"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*Modal *****************/}
      <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
        <div className="" role="document">
          <div className="">
              <CreateUpdateCompanyForm isUpdate={false} setShowModal={setShowModal}/>
          </div>
        </div>
      </Modal>
    </>
  );

  async function  startLoading () {
    Swal.fire({
      title: "Loading...",
      allowOutsideClick: true,
      allowEscapeKey: false,
      showConfirmButton: false,
      icon: "info",
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
  };

  async function fetchData (pageIndex){
    if (pageIndex < 1) return;
    if (searchQuery === "") {
      startLoading();
    } else {
      setIsLoadingQuery(true);
    }
    searchCompany(pageIndex, 10, searchQuery)
        .then((result) => {
          Swal.close();
          setIsLoadingQuery(false);
          setData(result.items);
          setTotalPages(result.totalPages);
          setCurrentPage(result.page);
          setTargetPage(result.page + 1);
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        })
        .catch((error) => {
          setIsLoadingQuery(false);
          Swal.close();
          handleError(error);
        });
  };
}
