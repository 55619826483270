import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import {cellStyle, hasAValue} from "../../utils/SharedFunctions";
export const columns_notification = [
	{
		Header : 'Mode',
		Footer : 'Mode',
		accessor: 'mode',
		Filter: ColumnFilter,
	},
	{
		Header : 'Type',
		Footer : 'Type',
		accessor: 'type',
		Filter: ColumnFilter,
	},
	{
		Header : 'Status',
		Footer : 'Status',
		accessor: 'status',
		Filter: ColumnFilter,
	},
	{
		Header : 'Title (NL)',
		Footer : 'Title (NL)',
		accessor: 'notificationData.titleNl',
		Filter: ColumnFilter,
	},
	{
		Header : 'Body (NL)',
		Footer : 'Body (NL)',
		accessor: 'notificationData.bodyNl',
		Cell: ({ value }) => {return cellStyle(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Made by',
		Footer : 'Made by',
		accessor: 'madeBy',
		Cell: ({ value }) => {return hasAValue(value)? value : "Unknown"},
		Filter: ColumnFilter,
	},
	{
		Header : 'Created',
		Footer : 'Created',
		accessor: 'createdAt',
		Cell: ({ value }) => {return (value?.toString().substring(0,10) + " " +value?.toString().substring(11,16))},
		Filter: ColumnFilter,
	}
]
