import { useState } from "react";
import Entity from "./Entity";
import Section from "./Section";
import Divider from "./Divider";
import Button from "./Button";
import { stopUserPaymentReminder } from "../../../../Api/ApiCalls";

const Payments = ({ userId, payments, confirmEndKwm }) => {


  return (
    <>
    <div class="d-flex justify-content-between align-items-center mb-3">
        <div class="h3 font-weight-bold text-primary">
          Payments
        </div>
    </div>
    <table className="static-table">
      <thead>
          <tr>
            <th>Payment</th>
            <th>Status</th>
            <th>Level of Reminder</th>
            <th>Reminder Active ?</th>
            <th>Link</th>
            <th>KWM</th>
          </tr>
      </thead>
      <tbody>
        {
          payments && 
          payments.map((payment, index) => {
            return (
              <tr>
                <td>Payment { payments.length - index }</td>
                <td>{ payment.status }</td>
                <td>{ payment.reminderLevel }</td>
                <td>{ payment.isReminderActive ? 'Yes' : 'No' }</td>
                <td>
                    <Button
                      text="Go To Payment"
                      variant="blue"
                      onClick={() => {
                        window.open(payment.paymentLink, '_blank').focus();
                      }}
                      iconClassName="fas fa-solid fa-globe"
                    />
                </td>
                <td>
                  {
                    payment.isReminderActive && (
                      <Button
                        text="End KWM"
                        variant="red"
                        onClick={() => {
                          confirmEndKwm(payment.paymentId)
                        }}
                        iconClassName="fas fa-solid fa-ban"
                      />
                    )
                  }
                  </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
    </>
  );
};

export default Payments;
