import React, {useEffect, useState} from 'react';
import {useTable, useGlobalFilter, useFilters, usePagination, useSortBy} from 'react-table';
import './../FilteringTable/filtering.css';
import {columns_notification} from "../FilteringTable/NotificationTabelsColumns";
import {fetchAllNotificationsCall, fetchLastUpdateNotificationCall} from "../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../config/Constants";
import {Modal} from "react-bootstrap";
import {hasAValue} from "../../utils/SharedFunctions";
import {Link} from "react-router-dom";
import {ApiSearchField} from "../ApiSearchField";
import {startSendingANotificationCall} from "../../Api/ApiCallsJobs";


export default function AllNotification() {
    const [data, setData] = useState([])
    const [totalPages, setTotalPages] = useState(undefined);
    const [currentPage, setCurrentPage] = useState(undefined);
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [notificationDetails, setNotificationDetails] = useState(undefined)
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setColumns(columns_notification)
        fetchAllNotifications(1)
    }, []);

    useEffect(() => {
        fetchAllNotifications(1);
    }, [searchQuery]);


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
    } = tableInstance


    const {pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Notifications</h4>
                    <Link to={"/new-notification"} className="btn btn-secondary">+ New Notification</Link>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <ApiSearchField
                            searchQuery={searchQuery}
                            setSearchQuery={setSearchQuery}
                        />
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th>
                                            {column.render('Header')}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => openModalShow(row.original)}> {cell.render('Cell')} </td>

                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                  <strong>
                  {currentPage} of {totalPages}
                </strong>
                  {""}
              </span>
                            <span className="table-index d-none">
                Go to page :{" "}
                                <input
                                    type="number"
                                    className="ml-2"
                                    defaultValue={pageIndex + 1}
                                    onChange={(e) => {
                                        const pageNumber = e.target.value
                                            ? Number(e.target.value)
                                            : 1;
                                        fetchAllNotifications(pageNumber);
                                    }}
                                />
              </span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button
                                    className=" previous-button"
                                    onClick={() => fetchAllNotifications(1)}
                                    disabled={currentPage === 1}
                                >
                                    {"<<"}
                                </button>

                                <button
                                    className="previous-button"
                                    onClick={() => fetchAllNotifications(currentPage - 1)}
                                    disabled={currentPage === 1}
                                >
                                    Previous
                                </button>
                                <button
                                    className="next-button"
                                    onClick={() => fetchAllNotifications(currentPage + 1)}
                                    disabled={currentPage === totalPages}
                                >
                                    Next
                                </button>
                                <button
                                    className=" next-button"
                                    onClick={() => fetchAllNotifications(totalPages)}
                                    disabled={currentPage === totalPages}
                                >
                                    {">>"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20">Notification</h4>
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                            {hasAValue(notificationDetails) && <div className="modal-body">
                                <div className="card border-top shadow">
                                    <div className="card-header border-0 pb-0">
                                        <h2 className="card-title">Overview</h2>
                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>General</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Mode</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Type</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Status</strong>
                                                <span className={"col-md-3 col-sm-10 fw-bold text-primary"}></span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.mode}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.type}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.status}</span>

                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Title (EN)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.titleEn}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Body (EN)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.bodyEn}</span>
                                            </li>
                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Title (NL)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.titleNl}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Body (NL)</strong>
                                                <span
                                                    className={"col-9"}>{notificationDetails.notificationData?.bodyNl}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Type</strong>
                                                <span className={"col-9 fw-bold text-primary"}>{notificationDetails.type?.replaceAll('_', ' ').toUpperCase()}</span>
                                            </li>

                                            {notificationDetails.type.toString().includes("url") ?
                                                <li className="list-group-item d-flex px-0 justify-content-between row">
                                                    <strong className={"col-3"}>URL</strong>
                                                    <span
                                                        className={"col-9 fw-bold text-primary"}>{notificationDetails.url}</span>
                                                </li> : notificationDetails.type.toString().includes("instore") ?
                                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                                        <strong className={"col-3"}>Action ID</strong>
                                                        <span
                                                            className={"col-9 fw-bold text-primary"}>{notificationDetails.actionId}</span>
                                                    </li> : notificationDetails.type.toString().includes("online") ?
                                                        <li className="list-group-item d-flex px-0 justify-content-between row">
                                                            <strong className={"col-3"}>SnipTech Store ID</strong>
                                                            <span
                                                                className={"col-9 fw-bold text-primary"}>{notificationDetails.storeId}</span>
                                                        </li> :
                                                        <li className="list-group-item d-flex px-0 justify-content-between row">
                                                            <strong className={"col-3"}>Jopp Job ID</strong>
                                                            <span
                                                                className={"col-9 fw-bold text-primary"}>{notificationDetails.jobId}</span>
                                                        </li>
                                            }

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Made by</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.madeBy}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Created at</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.createdAt}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Total receivers</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.totalReceivers}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Android</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Received</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Failed</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Total</strong>
                                                <span className={"col-md-3 col-sm-10 mb-2"}></span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.firebaseSent}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.firebaseFailed}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{(hasAValue(notificationDetails.firebaseSent) && hasAValue(notificationDetails.firebaseFailed)) &&
                                                    (parseInt(notificationDetails.firebaseSent) + parseInt(notificationDetails.firebaseFailed))}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>iOS</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Received</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Failed</strong>
                                                <strong className={"col-md-3 col-sm-10 mb-2"}>Total</strong>
                                                <span className={"col-md-3 col-sm-10 mb-2"}></span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.iosSent}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{notificationDetails.iosFailed}</span>
                                                <span
                                                    className={"col-md-3 col-sm-10 fw-bold text-primary"}>{(hasAValue(notificationDetails.iosSent) && hasAValue(notificationDetails.iosFailed)) &&
                                                    (parseInt(notificationDetails.iosSent) + parseInt(notificationDetails.iosFailed))}</span>
                                            </li>


                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Fake</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.fake ? "Yes" : "No"}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>iOS target</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.iosTarget ? "Yes" : "No"}</span>
                                                <strong className={"col-3"}>Android target</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{notificationDetails.androidTarget ? "Yes" : "No"}</span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </div>}

                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={() => {
                                    startSendingANotification()
                                }}>Start sending notification
                                </button>
                                <button type="button" onClick={() => setShowModal(false)}
                                        className="btn btn-warning">Close
                                </button>
                            </div>
                        </form>

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalShow(item) {
        setShowModal(true)
        setNotificationDetails(item)
    }

    function startSendingANotification() {
        startSendingANotificationCall({id: notificationDetails.id}).then(r => {
            toast.success(r.data.message, toastOptions)
        }).catch(error => {
            toast.warn(error.message, toastOptions)
        })
    }

    function fetchLastUpdateNotification(id) {

        let numberOfTimes = 50;
        let delay = 4000;

        for (let i = 0; i < numberOfTimes; i++) {
            setTimeout(() => {
                fetchLastUpdateNotificationCall(id).then(notification => {
                    setNotificationDetails(notification)
                }).catch(error => {

                })
                if (numberOfTimes % i === 0) {
                    fetchAllNotifications()
                }
            }, delay * i);

        }
    }

    function getFilters() {
        const apiFilters = [];
        if (searchQuery?.length) {
            apiFilters.push(`q=${encodeURIComponent(searchQuery)}`);
        }
        return apiFilters.join("&");
    }

    function fetchAllNotifications(page) {
        fetchAllNotificationsCall(page, 10, getFilters()).then(result => {
            setData(result?.items);
            setTotalPages(result.totalPages);
            setCurrentPage(result.page);
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions)
        })
    }
}
