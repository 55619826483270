import React, {useEffect, useState} from "react";
import {
  getCompanyByIdCall,
  downloadStatistics
} from "../../../../Api/ApiCalls";
import { handleError, transformDate} from "../../../../utils/SharedFunctions";
import Button from "../../Users/common/Button";
import {Modal} from "react-bootstrap";
import {DetailsCard} from "../../../../components/Common/DetailsCard";
import FavouritModal from "./FavouritModal";
import { CreateUpdateCompanyForm } from "../../../../components/Company/CreateUpdateCompanyForm";
import loader from "../../../../style/images/loader.gif";

export default function CompanyInfo({companyId, setIsSendStatistics}) {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [showFavouritModal, setShowFavouritModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [companyDetails, setCompanyDetails] = useState([]);

  const [updateFormData, setUpdateFormData] = useState({});
 
  useEffect(() => {
    getCompanyById();
  }, [companyId]);

const downloadStats = async () => {
  setShowLoader(true);
  
  const result = await downloadStatistics(companyId);
  const blob = new Blob([result], { type: result.type});
  const el = document.createElement("a");
  el.href = window.URL.createObjectURL(blob);
  el.download = `${updateFormData.name}-stats-report-${transformDate(new Date(), false)}.xlsx`;
  el.click();
  setShowLoader(false);
}
  return (
    <>
          <div className="d-flex justify-content-end my-4">
              <Button
                        onClick={() => {
                          setShowFavouritModal(true);
                        }}
                        text="Add To Favourite"
                        variant="primary"
                        data-toggle="modal"
                        iconClassName="far fa-heart"
                        className="me-3"
                      />
              <Button
                        onClick={() => setShowCompanyModal(true)}
                        text="Update"
                        variant="primary"
                        data-toggle="modal"
                        iconClassName="far fa-edit"
                        className="me-3"
                      />
              <Button
                        onClick={() => downloadStats()}
                        text="Download Statistics Report"
                        variant="primary"
                        data-toggle="modal"
                        iconClassName="fas fa-download"
                      />
          </div>
          {
              showLoader && (
                  <div id="full-loader">
                      <img src={loader} />
                  </div>
              )
          }
          <DetailsCard items={companyDetails} />

       {/* Company Modal */}
       <Modal
          className="modal fade"
          size={"xl"}
          show={showCompanyModal}
          onHide={setShowCompanyModal}
        >
          <div className="" role="document">
            <div className="">
                <CreateUpdateCompanyForm isUpdate companyId={companyId} formData={updateFormData} setShowModal={setShowCompanyModal} trigerAfterSubmit={getCompanyById}/>
            </div>
          </div>
        </Modal>

        <FavouritModal companyId={companyId} showModal={showFavouritModal} setShowModal={setShowFavouritModal} />
    </>
  );

  async function getCompanyById() {
    if (!companyId) {
      console.error("No companyId provided");
      return;
    }

    try {
      const result = await getCompanyByIdCall(companyId);
      const {name, website, logoUrl, backgroundImageUrl, isFreeToUse, isDisabled, weight, accountOwner, email, verificationCode, isSendStatistics, cities, companySubscriptionType} = result;
      const {app: appWeight = 0, web: webWeight = 0} = weight;

      let companyDetails = [
        {
          icon : 'fa-regular fa-building', name : 'Name', value : name
        },
        {
          icon : 'fa-solid fa-icons', name : 'Category', value : result?.category?.name
        },
        {
          icon : 'fa-solid fa-earth-americas', name : 'Website', value : website
        },
        {
          icon : 'fa-regular fa-bookmark', name : 'Status', value : result?.isDisabled === false ? "Visible" : "Hidden"
        },
        {
          icon : 'fa-solid fa-tags', name : 'Has Live Discounts ?', value : result?.actionsCount > 0 ? "Yes" : "No"
        },
        {
          icon : 'fa-solid fa-user', name : 'Account Owner', value : accountOwner.replaceAll('_', ' ')
        },
        {
          icon : 'fa-regular fa-envelope', name : 'Email', value : email
        },
        {
          icon : 'fa-solid fa-barcode', name : 'Verification Code', value : verificationCode
        },
        {
          icon : 'fa-solid fa-paper-plane', name : 'Send Statistic Updates ?', value : isSendStatistics? 'Yes':'No'
        },
        {
          icon : 'fa-solid fa-money-check-dollar', name : 'Subscription Type', value : companySubscriptionType.charAt(0).toUpperCase() + companySubscriptionType.slice(1).split('_').join('-')
        },
      ]
      setCompanyDetails(companyDetails);

      setUpdateFormData({
        name,
        website,
        categoryId: result?.category?.id ?? '',
        isFreeToUse,
        isDisabled,
        appWeight,
        webWeight,
        accountOwner,
        email,
        verificationCode,
        isSendStatistics,
        cityIds: cities.map(city => city.id),
        backgroundImage: backgroundImageUrl,
        logo: logoUrl,
        companySubscriptionType,
      });
      
      setIsSendStatistics(isSendStatistics)
    } catch (err) {
      handleError(err);
    }
  }
};
