import React from "react";
import {appUrls} from "../../../config/Constants";

export default function Step4({
                                  notificationItem,
                                  titleEn,
                                  bodyEn,
                                  titleNl,
                                  bodyNl,
                                  mode,
                                  type,
                                  userListForTestMode,
                                  goNextStep,
                                  goBack,
                                  saveNotification,
                                  selectedFiltersForProductionMode
                              }) {
    return (
        <>
            <section>
                <div className="row justify-content-center">
                    <div className="col-lg-6 mb-2 ">
                        <div className="card border-top shadow">
                            <div className="card-header border-0 pb-0">
                                <h2 className="card-title">Overview</h2>
                            </div>
                            <div className="card-body pb-0">

                                <ul className="list-group list-group-flush">
                                    {/*<li className="list-group-item d-flex px-0 justify-content-between">*/}
                                    {/*    <strong>Mode</strong>*/}
                                    {/*    <span className="mb-0">{mode}</span>*/}
                                    {/*</li>*/}
                                    {/*<li className="list-group-item d-flex px-0 justify-content-between">*/}
                                    {/*    <strong>Type</strong>*/}
                                    {/*    <span className="mb-0">{type}</span>*/}
                                    {/*</li>*/}
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>Title (EN)</strong>
                                        <span className={"col-9"}>{titleEn}</span>
                                    </li>
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>body (EN)</strong>
                                        <span className={"col-9"}>{bodyEn}</span>
                                    </li>
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>Title (NL)</strong>
                                        <span className={"col-9"}>{titleNl}</span>
                                    </li>
                                    <li className="list-group-item d-flex px-0 justify-content-between row">
                                        <strong className={"col-3"}>body (NL)</strong>
                                        <span className={"col-9"}>{bodyNl}</span>
                                    </li>

                                    {/*url*/}
                                    <li className="list-group-item d-flex px-0 justify-content-between">
                                        <strong>Notification item</strong>
                                        {type.toString().includes("url") &&
                                            <span className="mb-0">{notificationItem.url}</span>}


                                        {/*online*/}

                                        {type.toString().includes("online") && <div className="widget-media mb-2">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-2">
                                                            <img alt="" width="50"
                                                                 src={notificationItem.store.logo}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.store.title}</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}


                                        {/*instore*/}
                                        {type.toString().includes("instore") && <div className="widget-media mb-2">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-2">
                                                            <img alt="" width="50"
                                                                 src={appUrls.backend_api_image + notificationItem.action.iconId}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.action.companyName}</h5>
                                                            <small className="d-block">
                                                                {notificationItem.action.localization.nl.subtitle}
                                                            </small>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}

                                        {/*jobs*/}

                                        {type.toString().includes("job") && <div className="widget-media mb-2">
                                            <ul className="timeline">
                                                <li className={"cursor-pointer"}>
                                                    <div className={"timeline-panel"}>
                                                        <div className="media me-2">
                                                            <img alt="" width="50"
                                                                 src={notificationItem.job.logo}/>
                                                        </div>
                                                        <div className="media-body">
                                                            <h5 className="mb-1">{notificationItem.job.jobTitle}</h5>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>}


                                    </li>


                                    {mode.includes("test") &&
                                        <li className="list-group-item d-flex px-0 justify-content-between">
                                            <strong>Recipients</strong>
                                            <span className="m-1  d-flex flex-column justify-content-end text-right">
                                    {userListForTestMode.map((item, i) => {
                                        return (
                                            <span key={i} className="m-1 text-right">{item.email}</span>
                                        )
                                    })}
                                        </span>
                                        </li>}

                                    {mode.includes("production") &&
                                        <li className="list-group-item d-flex flex-column px-0 justify-content-start">
                                            <strong>Recipients</strong>
                                            <span className="m-1  d-flex flex-column justify-content-end text-right">
                                             <span
                                                 className="m-1 text-right">Country: {selectedFiltersForProductionMode.country === 1 ? "Netherlands" : "Belgium"}</span>
                                             <span className="m-1 text-right">Cities:
                                                 {selectedFiltersForProductionMode.cities.map((item) => {
                                                     return (<span className="m-1 text-right">{item.label}</span>)
                                                 })}</span>
                                                {!!selectedFiltersForProductionMode.limitation && <span
                                                    className="m-1 text-right">Users type: {selectedFiltersForProductionMode.limitation}</span>}
                                                {!!selectedFiltersForProductionMode.gender && <span
                                                    className="m-1 text-right">Users gender: {selectedFiltersForProductionMode.gender}</span>}
                                                {!!selectedFiltersForProductionMode.session && <span
                                                    className="m-1 text-right">Users session: {selectedFiltersForProductionMode.session} days ago</span>}
                                                {!!selectedFiltersForProductionMode.targetGroups && <span
                                                    className="m-1 text-right">Target group: {selectedFiltersForProductionMode.targetGroups}</span>}
                                        </span>
                                        </li>}
                                </ul>
                            </div>
                            <div className="card-footer pt-0 pb-0 text-center">
                                <div className="row">
                                    <div className="col-4 pt-3 pb-3 border-right">
                                        <h3 className="mb-1 text-primary text-uppercase">{mode}</h3>
                                        <span>Mode</span>
                                    </div>
                                    <div className="col-4 pt-3 pb-3 border-right">
                                        <h3 className="mb-1 text-primary text-uppercase">{type}</h3>
                                        <span>Type</span>
                                    </div>
                                    <div className="col-4 pt-3 pb-3">
                                        <h3 className="mb-1 text-primary">{userListForTestMode.length}</h3>
                                        <span>Recipients</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="text-end toolbar toolbar-bottom p-2">
                <button className="btn btn-secondary sw-btn-prev me-1"
                        onClick={() => goBack()}>Prev
                </button>
                {mode.includes("test") && <button className="btn btn-primary sw-btn-next ms-1"
                                                  onClick={() => saveNotification()}>Save
                </button>}

                {mode.includes("production") && <button className="btn btn-primary sw-btn-next ms-1"
                                                        onClick={() => saveNotification()}>Save
                </button>}
            </div>
        </>
    );

};
